$(document).ready(function() {

	$('body').on('click', '.js-reset', function(event) {
		event.preventDefault();
		setTimeout(function(){
			$('#contact-form').show();
			$('#status-form').hide();
			document.getElementById('contact-form').reset();
		},1000);
		$('.js-close_modal').trigger('click')
	});

	var category = getParameterByName('cat');
	if(category != ''){
		var tabNavs = $('.c-tabs__nav');
		var tabContainers = $('.c-tabs__container');
		$.getJSON('js/libs/products.json', function (data) {
			// console.log(data);
			var cat = data[category];

			// Set Header
			$('.c-hero__title').html(cat.title);
			$('.c-hero__subtitle').html(cat.caption);
			$('.c-hero__image').attr('src', cat.banner);
			// Set tabs
			tabNavs.empty();
			tabContainers.empty();

			$.each(cat.categories, function(i,item){
				var isLastElement = i == [item].length -1;
				$('<li class="c-tabs__tab">').html('<a href="#'+item.name.replace(/\s/g, '_')+'" class="c-tabs__link '+( i == 0 ? 'is-active' : '' )+'">'+item.name+'</a>').appendTo('.c-tabs__nav');
				$('<div id="'+item.name.replace(/\s/g, '_')+'" class="c-tabs__content '+( i == 0 ? 'is-active' : '' )+'">').appendTo('.c-tabs__container');
				$('<div class="row">').appendTo('#'+item.name.replace(/\s/g, '_'));
				$.each(cat.categories[i].products, function(index,products){;
					$('<div class="col-sm-4 col-md-3"><div class="o-product"><div class="o-product__figure js-getImage"><div class="js-image"><img src="'+products.image+'" alt="'+products.title+'"></div></div><h2 class="o-product__title">'+products.title+'</h2></div></div>').appendTo('#'+item.name.replace(/\s/g, '_')+'> .row');
				});
					if (isLastElement) {
						imagebg();
					}
			})
		});
	}

	function getParameterByName(name) {
		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results = regex.exec(location.search);
		return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	}

	$('#categories').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		adaptiveHeight: true,
		responsive:[
			{
				breakpoint: 992,
				settings: {
					centerPadding: '40px',
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 560,
				settings: {
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});


	$('body').on('click', '.js-nav_anchor', function(event) {
		event.preventDefault();
		hash = $(this).attr('href');
		if ($(hash).length) {
			$('html, body').animate({scrollTop: $(hash).offset().top - parseInt(60)}, 600);
		}else{
			window.location.href = 'index.html'+hash;
		}
	});

	// Tabs
	$('.c-tabs').on('click', '.c-tabs__link', function(event) {
		event.preventDefault();
		var target = $(this).attr('href');
		$('.c-tabs__content').removeClass('is-active');
		$('.c-tabs__link').removeClass('is-active');
		$(target).addClass('is-active');
		$(this).addClass('is-active');
		imagebg();
	});
	function imagebg() {
		$('.js-getImage').each(function(i, e) {
			var image;
			image = $(e).find('.js-image img').attr('src');
			$(e).find('.js-image').css('background-image', 'url(' + image + ')');
			return $(e).find('.js-image');
		});
	}
	// modal
	function modal() {
		var element;
		if ( $('.c-modal').length ) {
			$('body').on('click', '.js-launch_modal', function(event) {
				event.preventDefault();
				$('.c-modal').hide();
				element = $(event.target).attr('href');
				$(element).show();
				$('.c-layer').show();
				return false;
			});
			$('body').on('click', 'c-modal__close, .c-layer, .js-close_modal', function(event) {
				event.preventDefault();
				$('.c-modal,.c-layer').hide();
				return false;
			});
		}
	};
	modal();

	function scrollTo(hash) {
		location.hash = "#" + hash;
	}

});

var module = new Object();

module = {

	init : function() {
		this.menuMobile();
	},
	menuMobile : function() {
		if ( $('.js-menu').length ) {
			$('body').on('click', '.js-menu', function(event) {
				event.preventDefault();
				element = $(this);
				if(element.attr('state') == 1) {
					$(this).removeClass('is-active');
					$('.c-nav').removeClass('is-active');
					element.attr('state', 0);
				}else{
					$(this).addClass('is-active');
					$('.c-nav').addClass('is-active');
					element.attr('state', 1);
				}
			});
		}
	},


}

module.init();

if ($('.c-tabs').length) {
	document.documentElement.classList.remove("no-js");
	document.documentElement.classList.add("js");

	var pnProductNav = document.getElementById("pnProductNav");
	var pnProductNavContents = document.getElementById("pnProductNavContents");

	pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));

	function determineOverflow(content, container) {
	    var containerMetrics = container.getBoundingClientRect();
	    var containerMetricsRight = Math.floor(containerMetrics.right);
	    var containerMetricsLeft = Math.floor(containerMetrics.left);
	    var contentMetrics = content.getBoundingClientRect();
	    var contentMetricsRight = Math.floor(contentMetrics.right);
	    var contentMetricsLeft = Math.floor(contentMetrics.left);
		// console.log(containerMetricsLeft, contentMetricsLeft, containerMetricsRight, contentMetricsRight);
	 //    if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
	 //        return "both";
	 //    } else if (contentMetricsLeft < containerMetricsLeft) {
	 //        return "left";
	 //    } else if (contentMetricsRight > containerMetricsRight) {
	 //        return "right";
	 //    } else {
	 //        return "none";
	 //    }
	}
}


// Enviar emial

(function(){
	emailjs.init("user_MVMRv81ktq8sSgvnoAwkM");
})();

window.onload = function() {
	document.getElementById('contact-form').addEventListener('submit', function(event) {
		event.preventDefault();
		emailjs.sendForm('smtp_server', 'contact', this).then(function(response) {
       // console.log('SUCCESS!', response.status, response.text);
       $('#contact-form').hide();
       $('#status-form').show();
    }, function(error) {
    		$('#status-form').children('.o-message').html('No hemos podido enviar su mensaje, favor inténtelo más tarde');
      	// console.log('FAILED...', error);
    });
	});
}
